import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const Portfolio = () => (
  <Layout>
    <SEO title="Förstärkeriets projekt" />
    <h2>Projekt</h2>

    <h3>Film</h3>
    <p>
      Ljudläggning, tramp och mix i samarbete med LjudBang av kortfilmen
      Prinsesspojken
    </p>
    <blockquote>
      Prinsesspojken är regisserad av Sosi Chamoun och har vunnit priser på Ann
      Arbor Film Festival (2017), Cleveland International Film Festival (2017)
      och Sveriges Kortfilmsfestival (2017).
    </blockquote>
    <ul>
      <li>
        <a href="http://www.imdb.com/title/tt5872552/fullcredits?ref_=tt_cl_sm#cast">
          IMDB
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/prinsesspojken/">Facebook</a>
      </li>
    </ul>

    <h3>Musik</h3>
    <div class="embed-container">
      <iframe
        src="https://www.youtube.com/embed/33YxfnY0VgM"
        frameborder="0"
        allowfullscreen
      />
    </div>
    <div class="embed-container">
      <iframe
        src="https://www.youtube.com/embed/YxINKj2FMTs"
        frameborder="0"
        allowfullscreen
      />
    </div>
    <h3>Radio</h3>
    <ul>
      <li>
        <a href="https://sverigesradio.se/sida/avsnitt/1077315?programid=2071">
          Sommar i P1 med Ingrid Wall
        </a>
      </li>
      <li>
        <a href="https://sverigesradio.se/sida/avsnitt/1077319?programid=2071">
          Sommar i P1 med Thure Lindhardt
        </a>
      </li>
      <li>
        <a href="https://sverigesradio.se/sida/avsnitt/921581?programid=2071">
          Sommar i P1 med Karin Laserow
        </a>
      </li>
    </ul>
    <p>
      Medverkat som tekniker vid sändningar för Sveriges Radio i följande
      program:
    </p>
    <ul>
      <li> P1 Kultur </li>
      <li> Ring P1 </li>
      <li> Sommar i P1 </li>
      <li> Dagens dikt </li>
      <li> OBS </li>
      <li> P2 Live </li>
      <li> Relationsradion </li>
      <li> Musikguiden i P3 </li>
      <li> DinGata </li>
      <li> P4 Extra </li>
      <li> Karlavagnen </li>
      <li> Fredag i P4 </li>
      <li> Kvällspasset </li>
      <li> Vi i Femman </li>
      <li> Livemusik i P4 Malmöhus och UR Dokumentär </li>
    </ul>
  </Layout>
)

export default Portfolio
